@tailwind base;
@tailwind components;
@tailwind utilities;
body {
    background-color: #bcd9f5
}

.card {
    max-width: 25rem;
    padding: 0;
    border: none;
    border-radius: 0.5rem
}

a.active {
    border-bottom: 2px solid #55c57a
}

.nav-link {
    color: white;
    font-weight: 500
}

.nav-link:hover {
    color: #55c57a
}

.nav-pills .nav-link.active {
    color: black;
    background-color: white;
    border-radius: 0.5rem 0.5rem 0 0;
    font-weight: 600
}

.tab-content {
    padding-bottom: 1.3rem
}

.form-control {
    background-color: rgb(241, 243, 247);
    border: none
}

.nav-link span {
    margin-left: 0.5rem;
    padding: 1px 10px;
    border-radius: 4px;
    font-weight: 300
}

.third {
    padding: 0 1.5rem 0 1.5rem
}

.nav-link label {
    font-weight: 500;
    color: rgb(104, 104, 104)
}

.btn-success {
    float: right
}

.form-control:focus {
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset, 0px 0px 7px rgba(0, 0, 0, 0.2)
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: ""
}

ul {
    list-style: none;
    margin-top: 1rem;
    padding-inline-start: 0
}

.search {
    padding: 0 1rem 0 1rem
}

.ccontent li .wrapp {
    padding: 0.3rem 1rem 0.001rem 1rem
}

.ccontent li .wrapp div {
    font-weight: 600
}

.ccontent li .wrapp p {
    font-weight: 360
}

.ccontent li:hover {
    background-color: rgb(117, 93, 255);
    color: white
}

.addinfo {
    padding: 0 1rem
}

.MyBorder{
    border-width: 1px;
}
.backgroundColor{
    /* background-color: #ffef0f00;
    outline:none;
    border-color:rgba(255, 255, 255, 0.514); */
    background-color: #ffffff15;

}
.backgroundColor:focus{
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.275) inset, 0px 0px 7px rgba(0, 0, 0, 0.378);
    /* outline:  rgba(255, 255, 255, 0.213);; */
    outline:none;
    border:1px solid;
    border-color:rgba(255, 255, 255, 0.514);
    box-shadow:0 0 10px rgba(255, 255, 255, 0.226);
}