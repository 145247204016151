.body{
    background-image: linear-gradient(0deg, hsla(0, 100%, 60%, 0.9) 0%, hsla(0, 48%, 45%, 1) 35%, hsla(0, 0%, 0%, 1) 100%);

}

.sidebar-wrapper{
    transition: 0;
}
.myback{
    background-color: rgb(0 0 0 / 14%)
}
.myback:hover{
    background-color: rgb(0 0 0 / 20%)
}